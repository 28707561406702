import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetEmergencyFundLocationQuery
  from '@/modules/flagship/catalogs/emergency-fund-location/application/query/get-emergency-fund-location-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import {
  CreateEmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/create-emergency-fund-investor-goal-state-manager';

// Domain
import {
  EmergencyFundLocationEntity,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/entities/emergency-fund-location-entity';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsWizardCustomizeFundViewModel {
  @Inject(TYPES.GET_EMERGENCY_FUND_LOCATION_QUERY)
  private readonly get_emergency_fund_location_query!: GetEmergencyFundLocationQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CREATE_EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly emergency_fund_investor_goal_state!: CreateEmergencyFundInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_customize_fund';

  view: Vue;

  emergency_fund_location_options: Array<EmergencyFundLocationEntity> = [];

  emergency_fund_location_selected = '';

  private investor_profile_id = '';

  private investor_profile_dto: InvestorProfileDto = {
    id: this.investor_profile_id,
    emergency_fund_locations: {},
  };

  investor_goal_state = this.emergency_fund_investor_goal_state.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_continue_btn_disabled() {
    return this.emergency_fund_location_selected === '' || this.investor_goal_state.is_loading;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setCustomGoal() {
    const emergency_fund_location_value = [];

    emergency_fund_location_value.push(this.emergency_fund_location_options.find(
      (item) => item.name === this.emergency_fund_location_selected,
    ));

    this.investor_profile_dto.emergency_fund_locations = {
      values: emergency_fund_location_value,
    };
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
      this.investor_profile_dto.id = this.investor_profile_id;
      if (investor_profile.emergency_fund_locations.values) {
        const emergency_fund_locations = investor_profile.emergency_fund_locations.values;
        this.emergency_fund_location_selected = emergency_fund_locations[0].name;
      }
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  getEmergencyFundLocationOptions = async () => {
    try {
      this.emergency_fund_location_options = await this.get_emergency_fund_location_query.execute();
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_emergency_fund_location_options'));
    }
  };

  async initialize() {
    this.investor_goal_state.is_loading = true;
    await this.getInvestorProfile();
    await this.getEmergencyFundLocationOptions();
    this.investor_goal_state.is_loading = false;
  }

  updateInvestorProfile = async () => {
    try {
      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      return true;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  };

  onSubmit = async () => {
    this.investor_goal_state.is_loading = true;
    this.setCustomGoal();
    const stored = await this.updateInvestorProfile();
    this.investor_goal_state.is_loading = false;
    if (stored) {
      this.view.$emit('nextStep');
    }
  }
}
